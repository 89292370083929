import {
  createTheme,
  CssBaseline,
  responsiveFontSizes,
  ThemeProvider,
} from "@mui/material";
import React from "react";

let theme = createTheme({
  spacing: 10,
  breakpoints: {
    values: {
      xs: 0,
      xs2: 400,
      sm2: 600,
      sm: 768,
      md: 992,
      md2: 1200,
      lg: 1280,
      xl: 1400,
    },
  },
  palette: {
    mode: "dark",
    background: {
      default: "#051537",
    },
  },
  typography: {
    fontFamily: ["Poppins", "Helvetica", "Arial"].join(","),
    fontSize: 14,
    color: "#F9F7F4",
    body1: {
      fontWeight: 300,
      fontFamily: ["Poppins", "Helvetica", "Arial"].join(","),
      lineHeight: 1.6,
      fontSize: 15,
    },
    body2: {
      // fontFamily: ["IBM Plex Sans", "Helvetica", "Arial"].join(","),
      fontFamily: ["Poppins", "Helvetica", "Arial"].join(","),
      fontSize: 14,
    },
    h1: {
      fontWeight: 600,
      fontFamily: ["Poppins", "Helvetica", "Arial"].join(","),
      fontSize: 65.34,
      lineHeight: 1.1,
      letterSpacing: "-0.01em",
    },
    h2: {
      fontWeight: 600,
      fontFamily: ["Poppins", "Helvetica", "Arial"].join(","),
      fontSize: 50.52,
      lineHeight: 1.09,
      letterSpacing: "-0.01em",
    },
    h3: {
      fontWeight: 600,
      fontFamily: ["Poppins", "Helvetica", "Arial"].join(","),
      fontSize: 37.9,
      lineHeight: 1.2,
      letterSpacing: "-0.01em",
    },
    h4: {
      fontWeight: 600,
      fontFamily: ["Poppins", "Helvetica", "Arial"].join(","),
      fontSize: 28.43,
      lineHeight: 1.19,
      letterSpacing: "-0.01em",
    },
    h5: {
      fontWeight: 600,
      fontFamily: ["Poppins", "Helvetica", "Arial"].join(","),
      fontSize: 21.33,
      lineHeight: 1.12,
      letterSpacing: "-0.01em",
    },
    h6: {
      fontWeight: 600,
      fontFamily: ["Poppins", "Helvetica", "Arial"].join(","),
      fontSize: 17,
      lineHeight: 1.1,
      letterSpacing: "-0.01em",
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          position: "relative",
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: "#fff",
          textDecoration: "none",
          lineHeight: 1,
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          background: "transparent",
          boxShadow: "none",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: 12,
          lineHeight: 1,
          fontWeight: 600,
          color: "#fff",
          textTransform: "none",
          "&:hover": {
            backgroundColor: "transparent",
          },
          "& svg": {
            fill: "currentColor",
          },
        },
        outlined: {
          borderRadius: 0,
          padding: "13px 20px",
          border: "1px solid rgba(255,255,255, .5)",
          lineHeight: 1,
          "& svg": {
            fill: "currentColor",
          },
          "&:hover": {
            backgroundColor: "#fff",
            color: "#000",
            "& svg": {
              fill: "currentColor",
            },
          },
        },
        text: {
          textTransform: "none",
          padding: 0,
          minWidth: "initial",
          "&:hover .MuiButton-endIcon": {
            transform: "translateX(4px)",
          },
        },
        endIcon: {
          marginLeft: 8,
          transition: ".3s ease-in-out",
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          background: "transparent",
          boxShadow: "none",
          margin: "16px auto",
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          background: "#0C1F47",
          border: "1px solid #384E7C",
          textAlign: "left",
          "& svg": {
            fill: "#fff",
          },
          "& .MuiAccordionSummary-expandIconWrapper:before": {
            content: '"+"',
            fontSize: 30,
            fontWeight: "bold",
          },
          "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded:before": {
            content: '"-"',
            color: "#1CE7C2",
          },
          "& .MuiAccordionSummary-content": {
            margin: 0,
          },
          "& .MuiAccordionSummary-content.Mui-expanded": {
            margin: "0",
          },
          "& h4": {
            fontSize: "26px",
          },
        },
      },
    },
    MuiCircularProgress: {
      styleOverrides: {
        root: {
          color: "#1CE7C2",
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        root: {
          borderRadius: 0,
          outline: "none",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: 0,
          borderColor: "#4B608D",
          "&:focus": {
            outline: 0,
          },
        },
      },
    },
    MuiTable: {
      styleOverrides: {
        root: {
          background: "#0C1F47",
          textAlign: "center",
          border: "1px solid #384E7C",
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          textAlign: "center",
          border: "1px solid #384E7C",
        },
      },
    },
  },
});

theme = responsiveFontSizes(theme);

const TopLayout = ({ children }) => (
  <ThemeProvider theme={theme}>
    <CssBaseline />
    {children}
  </ThemeProvider>
);

export default TopLayout;
